export enum Translation {
	LANGUAGE = 'language',
	QUIZBUTTONS_DONOTKNOW = 'quizButtons.doNotKnow',
	QUIZBUTTONS_REPEAT = 'quizButtons.repeat',
	QUIZBUTTONS_IKNOW = 'quizButtons.IKnow',
}
export enum Profile {
	TABS_TITLE = 'tabs.title',
	TABS_ITEMS_PERSONALINFORMATION = 'tabs.items.personalInformation',
	TABS_ITEMS_ABOUTME = 'tabs.items.aboutMe',
	TABS_ITEMS_SKILLS = 'tabs.items.skills',
	TABS_ITEMS_PROJECTS = 'tabs.items.projects',
	TABS_ITEMS_EXPERIENCE = 'tabs.items.experience',
	TABS_ITEMS_EDUCATION = 'tabs.items.education',
	SKILLFORM_SPECIALIZATIONSELECT = 'skillForm.specializationSelect',
	SKILLFORM_EMPTYSPECIALIZATIONSELECT = 'skillForm.emptySpecializationSelect',
	SKILLFORM_SKILLSELECT = 'skillForm.skillSelect',
	SKILLFORM_EMPTYSKILLSELECT = 'skillForm.emptySkillSelect',
	SKILLFORM_SELECTEDSKILLS = 'skillForm.selectedSkills',
	SKILLFORM_YOURSKILLS = 'skillForm.yourSkills',
	SKILLFORM_YOURSKILLSTEXT = 'skillForm.yourSkillsText',
	SKILLFORM_SUBMITBUTTONTEXT = 'skillForm.submitButtonText',
}

export enum Specialization {
	SKILLFORM_SPECIALIZATIONSELECT = 'skillForm.specializationSelect',
	SKILLFORM_EMPTYSPECIALIZATIONSELECT = 'skillForm.emptySpecializationSelect',
}
export enum Interview {
	PREPARATION_TITLE = 'preparation.title',
	PREPARATION_LINKTEXT = 'preparation.linkText',
	PREPARATION_PROGRESSBARTITLE = 'preparation.progressBarTitle',
	STATS_TITLE = 'stats.title',
	STATS_LINKTEXT = 'stats.linkText',
	STATS_STATSSTUDIED_ALLQUESTIONS = 'stats.statsStudied.allQuestions',
	STATS_STATSSTUDIED_NOTSTUDIED = 'stats.statsStudied.notStudied',
	STATS_STATSSTUDIED_STUDIED = 'stats.statsStudied.studied',
	QUESTIONS_TITLE = 'questions.title',
	QUESTIONS_STUDIED = 'questions.studied',
	QUESTIONS_RATING = 'questions.rating',
	QUESTIONS_COMPLEXITY = 'questions.complexity',
	HISTORY_PREPARATION_TITLE = 'history_preparation.title',
	HISTORY_PREPARATION_LINKTEXT = 'history_preparation.linkText',
	HISTORY_PREPARATION_RESULTTEXT = 'history_preparation.resultText',
}

export enum InterviewHistory {
	TITLE = 'title',
	STARTDATEINTERVIEWTITLE = 'startDateInterviewTitle',
	TOTALQUESTIONSTITLE = 'totalQuestionsTitle',
	RESULTTITLE = 'resultTitle',
}

export enum InterviewQuiz {
	PROGRESSBARTITLE = 'progressBarTitle',
	COMPLETEQUIZBUTTON = 'completeQuizButton',
}

export enum InterviewQuizResult {
	RESULTINTERVIEW_RESULTTITLE = 'resultInterview.resultTitle',
	RESULTINTERVIEW_QUESTIONTITLE = 'resultInterview.questionTitle',
	RESULTINTERVIEW_ALLPASSEDQUESTIONTITLE = 'resultInterview.allPassedQuestionTitle',
	QUESTIONSTATS_PASSED = 'questionStats.passed',
	QUESTIONSTATS_TIMESPENT = 'questionStats.timeSpent',
	QUESTIONSTATS_DATE = 'questionStats.date',
	QUESTIONSTATS_DURATION = 'questionStats.duration',
}

export enum InterviewStatistics {
	PROGRESS_TITLE = 'progress.title',
	QUESTIONSTATS_TITLE = 'questionStats.title',
	QUESTIONSTATS_PASSEDQUESTIONS = 'questionStats.passedQuestions',
	QUESTIONSTATS_NOTSTUDIED = 'questionStats.notStudied',
	QUESTIONSTATS_SAVED = 'questionStats.saved',
	QUESTIONSTATS_STUDIED = 'questionStats.studied',
	ATTEMPTSTATS_TITLE = 'attemptStats.title',
	ATTEMPTSTATS_BESTRESULT = 'attemptStats.bestResult',
	ATTEMPTSTATS_WORSTRESULT = 'attemptStats.worstResult',
	ATTEMPTSTATS_AVGRESULT = 'attemptStats.avgResult',
}
